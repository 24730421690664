.modal-registration {
  padding: 42px max(10px, 1vmin) max(20px, 2vmin);
  border-radius: max(10px, 1vmin);
  background-color: rgba(0, 0, 0, 0.5);

  &__image {
    overflow: hidden;
    max-width: 600px;
    border-radius: max(10px, 1vmin);
  }

  &__button {
    padding-top: max(20px, 2vmin);
  }
}
