.btn {
  display: grid;
  place-content: center;
  width: fit-content;
  height: 46px;
  margin: auto;
  padding: 0 max(20px, 3vmin);
  border-radius: 9.5px;
  background-size: 100% 50%, 100% 50%;
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 0;
  cursor: pointer;
  color: White;
  text-decoration: none;

  @include min(phone-landscape) {
    height: 52px;
    font-size: 18px;
  }

  &--orange {
    background: linear-gradient(to right, var(--c-orange-pink), var(--c-bright-pink));
  }

  &--voilet {
    background: linear-gradient(to right, var(--c-blue-dust), var(--c-bright-pink));
  }
}
