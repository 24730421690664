@mixin header-hero-slogan($z-index) {
  position: absolute;
  inset: 34vmin 0 0;
  z-index: $z-index;
  margin: auto;
  max-width: 1400px;
  filter: drop-shadow(0 0.3vmin 0.3vmin rgba(0, 0, 0, 0.5));
  width: 160vw;
  transform: translateX(-30vw);

  @include min(phone-landscape) {
    inset: 0;
    width: unset;
    transform: none;
  }

  img {
    object-fit: contain;
    object-position: top center;

    @include min(phone-landscape) {
      object-fit: contain;
      object-position: center;
    }
  }
}

@mixin characters($translate, $origin) {
  position: relative;
  transform: scale(1.5) translate(5%, 10%);
  transform-origin: $origin;
  aspect-ratio: 1;

  @include min(phone-landscape) {
    width: min(50%, 800px);
    transform: translateX($translate);
  }
}

canvas#lightning {
  position: absolute;
  inset: -15vmin 0 0;
  margin: auto;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.header-hero {
  overflow: hidden;
  position: relative;
  padding-top: 58vmin;

  &::after {
    content: '';
    background: linear-gradient(transparent, var(--c-black-blue) 97.5%);
    pointer-events: none;
    width: 100%;
    height: 20vmin;
    position: absolute;
    inset: auto 0 0;
    z-index: 2;
  }

  &::before {
    content: '';
    background: linear-gradient(var(--c-black-blue), transparent 97.5%);
    pointer-events: none;
    width: 100%;
    height: 20vmin;
    position: absolute;
    inset: 0 0 auto;
    z-index: 2;
  }

  @include min(phone-landscape) {
    padding-top: 10vmin;
  }

  .image-container--stripes {
    @include header-hero-slogan(1);
  }

  .image-container--text {
    @include header-hero-slogan(3);
  }

  &__characters {
    position: relative;
    z-index: 2;
    margin: auto;
    max-width: 1720px;
    display: flex;
    justify-content: space-between;

    .image-container--zeus {
      @include characters(-30%, right bottom);

      img.character-glow-zeus {
        filter:
          drop-shadow(0 calc(var(--shadow-displacement) * 0.8vmin) 1.8vmin hsla(var(--shadow-color), 100%, 70%, 1))
          drop-shadow(0 calc(var(--shadow-displacement) * -1.7vmin) 1.4vmin hsla(calc(var(--shadow-color) + 20), 90%, 50%, 0.6));
      }
    }

    .image-container--trait {
      @include characters(30%, left bottom);

      img.character-glow-trait {
        filter:
          drop-shadow(0 calc(var(--shadow-displacement) * 0.8vmin) 1.8vmin hsla(var(--shadow-color), 100%, 50%, 1))
          drop-shadow(0 calc(var(--shadow-displacement) * -1.7vmin) 1.4vmin hsla(calc(var(--shadow-color) + 20), 90%, 30%, 0.6));
      }
    }
  }

  .image-container--hero-background {
    position: absolute;
    inset: 0;
    margin: auto;

    img {
      object-fit: cover;
      object-position: bottom;
    }
  }
}

//@keyframes shadowColorAndDisplacementZeus {
//  0%, 100% {
//    filter: drop-shadow(0 0vmin 1.8vmin hsla(120, 100%, 70%, 1))
//            drop-shadow(0 0vmin 1.4vmin hsla(140, 90%, 50%, 0.6));
//  }
//  50% {
//    filter: drop-shadow(0 3vmin 1.8vmin hsla(220, 100%, 70%, 1))
//            drop-shadow(0 -3vmin 1.4vmin hsla(240, 90%, 50%, 0.6));
//  }
//}
//
//@keyframes shadowColorAndDisplacementTrait {
//  0%, 100% {
//    filter: drop-shadow(0 0vmin 1.8vmin hsla(300, 100%, 50%, 1))
//            drop-shadow(0 0vmin 1.4vmin hsla(320, 90%, 30%, 0.6));
//  }
//  50% {
//    filter: drop-shadow(0 3vmin 1.8vmin hsla(385, 100%, 50%, 1))
//            drop-shadow(0 -3vmin 1.4vmin hsla(405, 90%, 30%, 0.6));
//  }
//}
//
//.character-glow-zeus {
//  animation: shadowColorAndDisplacementZeus 3s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
//}
//
//.character-glow-trait {
//  animation: shadowColorAndDisplacementTrait 3s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
//}
