.slot-cards {
  display: flex;
  flex-direction: column;
  gap: 15px 0;

  @include min(phone-portrait) {
    display: grid;
    grid-template: auto / auto auto;
    gap: 20px;
  }

  @include min(tablet-portrait) {
    display: grid;
    grid-template: repeat(12, 1fr) / repeat(4, 1fr);
    gap: 20px;
  }

  &__item {
    overflow: hidden;
    aspect-ratio: 16 / 9;
    border-radius: 7.5px;
    transform: translateZ(0);
    background-color: #1C0B37;
    color: #ffffff;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    video.video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.02);
      transition: transform 0.5s ease-in-out;

      @include min(phone-landscape) {
        &:hover {
          transform: scale(1.2);
        }
      }

      &--hidden {
        display: none;
      }
    }

    @include min(tablet-portrait) {
      &:nth-of-type(5) {
        grid-row: 1 / 3;
        grid-column: 3 / 5;
        aspect-ratio: 16 / 9.25;
      }

      &:nth-of-type(12) {
        grid-row: 4 / 6;
        grid-column: 2 / 4;
        aspect-ratio: 16 / 9.25;
      }

      &:nth-of-type(20) {
        grid-row: 7 / 9;
        grid-column: 1 / 3;
        aspect-ratio: 16 / 9.25;
      }

      &:nth-of-type(33) {
        grid-row: 10 / 12;
        grid-column: 3 / 5;
        aspect-ratio: 16 / 9.25;
      }
    }
  }

  &-mobile {
    @include min(phone-portrait) {
      display: none;
    }

    .btn {
      width: 100%;
    }
  }
}
