.gridline {
  --gridlinePadding: var(--padding, 20px);
  --gridlineGap: var(--gap, 20px);
  --gridlineCenter: min(calc(100vw - (var(--gridlinePadding) * 2)), var(--width, 100%));
  --gridlineColumn: calc((var(--gridlineCenter)  - 11 * var(--gridlineGap)) / 12);
  --gridlineMargin: calc((100vw - var(--gridlineCenter)) / 2);
  box-sizing: border-box;
}

.gridline > * {
  box-sizing: inherit;
}

.gridline:not(.gridline--scrolling):not(.gridline--collapse) {
  display: flex;
}

.gridline:not(.gridline--collapse):not(.gridline--scrolling) > * {
  margin-left: calc(var(--gridlineColumn) * var(--offset, 0)  + (var(--offset, 0)  - 1) * var(--gridlineGap)  + var(--gridlineGap));
  flex: 1 0 calc(var(--gridlineColumn) * var(--column, 12)  + (var(--column, 12)  - 1) * var(--gridlineGap)  + var(--gridlineInfinite, 0px));
}

.gridline:not(.gridline--collapse):not(.gridline--scrolling) > :not(:last-child) {
  margin-right: var(--gridlineGap);
}

.gridline--center:not(.gridline--scrolling) > [class*="edge"], .gridline--left:not(.gridline--scrolling) > [class*="edge"], .gridline--right:not(.gridline--scrolling) > [class*="edge"] {
  --gridlineInfinite: var(--gridlineMargin);
}

.gridline--center:not(.gridline--scrolling) > [class*="edge"]:first-child, .gridline--left:not(.gridline--scrolling) > [class*="edge"]:first-child, .gridline--right:not(.gridline--scrolling) > [class*="edge"]:first-child {
  margin-left: calc(var(--gridlineMargin) * -1);
}

.gridline > [class*="edge"]:not(:first-child) {
  margin-right: calc(var(--gridlineMargin) * -1);
}

.gridline--center {
  max-width: var(--gridlineCenter);
  margin-left: auto;
  margin-right: auto;
}

.gridline--left, .gridline--right {
  max-width: calc(var(--gridlineCenter)  + var(--gridlineMargin));
}

.gridline--left > *, .gridline--right > * {
  --gridlineColumn: calc((100% - 11 * var(--gridlineGap)) / 12);
}

.gridline--right {
  transform: translateX(var(--gridlineMargin));
}

.gridline--scrolling {
  max-width: min(var(--gridlineCenter)  + var(--gridlinePadding) * 2, 100vw);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  margin: auto;
  overflow: auto hidden;
}

@media (pointer: coarse) {
  .gridline--scrolling {
    padding-bottom: 10px;
  }
}

.gridline--scrolling [class*="scrolling"] {
  float: left;
  padding-left: var(--gridlinePadding);
  padding-right: var(--gridlinePadding);
  min-width: 100%;
}

:root {
  --max-fs: 1;
  --min-fs: .75;
  --max-vw: 120;
  --min-vw: 80;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc(var(--min-vw) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw)  - var(--min-vw)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-default, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-size: var(--font-size-clamp);
  line-height: var(--default-line-height);
  font-weight: 400;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-ext-italic.67416da3.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-italic.3bab7b29.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-vietnamese-italic.c85c0321.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-ext-italic.5899b6bd.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-italic.17be0412.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-ext-normal.225a572b.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-cyrillic-normal.cb2f6287.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-vietnamese-normal.fd255957.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-ext-normal.f2e35548.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("montserrat-latin-normal.bcad0343.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --z-index-particles: 2;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-womans: 12;
  --z-index-modal: 100;
  --c-white: #fff;
  --c-light-sand: #fefeb2;
  --c-black-blue: #0e0123;
  --c-orange-pink: #ff9f68;
  --c-bright-pink: #ff24bf;
  --c-blue-dust: #3526e2;
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --min-fs: .6;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}

@media (width >= 992px) {
  :root {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

:root .gridline {
  --width: 1100px;
  --padding: 15px;
  --gap: 20px;
}

@media (width >= 576px) {
  :root .gridline {
    --padding: 40px;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.headline-medium {
  font-weight: 700;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 600;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 600;
}

.title-medium {
  max-inline-size: none;
  font-size: 1.8rem;
  font-weight: 600;
}

.body-large {
  font-size: 1.6rem;
}

ul {
  margin-left: 36px;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

html, body {
  position: relative;
}

body {
  background-color: var(--c-black-blue);
  color: var(--c-white);
  width: 100vw;
  min-height: 100dvh;
  display: grid;
  overflow: hidden auto;
}

.layout {
  padding-bottom: var(--v-offset-lg);
  width: 100vw;
  overflow: clip auto;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.slot-cards {
  flex-direction: column;
  gap: 15px 0;
  display: flex;
}

@media (width >= 576px) {
  .slot-cards {
    grid-template: auto / auto auto;
    gap: 20px;
    display: grid;
  }
}

@media (width >= 992px) {
  .slot-cards {
    grid-template: repeat(12, 1fr) / repeat(4, 1fr);
    gap: 20px;
    display: grid;
  }
}

.slot-cards__item {
  aspect-ratio: 16 / 9;
  color: #fff;
  background-color: #1c0b37;
  border-radius: 7.5px;
  overflow: hidden;
  transform: translateZ(0);
}

.slot-cards__item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.slot-cards__item video.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in-out;
  transform: scale(1.02);
}

@media (width >= 768px) {
  .slot-cards__item video.video:hover {
    transform: scale(1.2);
  }
}

.slot-cards__item video.video--hidden {
  display: none;
}

@media (width >= 992px) {
  .slot-cards__item:nth-of-type(5) {
    aspect-ratio: 16 / 9.25;
    grid-area: 1 / 3 / 3 / 5;
  }

  .slot-cards__item:nth-of-type(12) {
    aspect-ratio: 16 / 9.25;
    grid-area: 4 / 2 / 6 / 4;
  }

  .slot-cards__item:nth-of-type(20) {
    aspect-ratio: 16 / 9.25;
    grid-area: 7 / 1 / 9 / 3;
  }

  .slot-cards__item:nth-of-type(33) {
    aspect-ratio: 16 / 9.25;
    grid-area: 10 / 3 / 12 / 5;
  }
}

@media (width >= 576px) {
  .slot-cards-mobile {
    display: none;
  }
}

.slot-cards-mobile .btn {
  width: 100%;
}

canvas#lightning {
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: -15vmin 0 0;
}

.header-hero {
  padding-top: 58vmin;
  position: relative;
  overflow: hidden;
}

.header-hero:after {
  content: "";
  background: linear-gradient(transparent, var(--c-black-blue) 97.5%);
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 20vmin;
  position: absolute;
  inset: auto 0 0;
}

.header-hero:before {
  content: "";
  background: linear-gradient(var(--c-black-blue), transparent 97.5%);
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 20vmin;
  position: absolute;
  inset: 0 0 auto;
}

@media (width >= 768px) {
  .header-hero {
    padding-top: 10vmin;
  }
}

.header-hero .image-container--stripes {
  z-index: 1;
  filter: drop-shadow(0 .3vmin .3vmin #00000080);
  width: 160vw;
  max-width: 1400px;
  margin: auto;
  position: absolute;
  inset: 34vmin 0 0;
  transform: translateX(-30vw);
}

@media (width >= 768px) {
  .header-hero .image-container--stripes {
    width: unset;
    inset: 0;
    transform: none;
  }
}

.header-hero .image-container--stripes img {
  object-fit: contain;
  object-position: top center;
}

@media (width >= 768px) {
  .header-hero .image-container--stripes img {
    object-fit: contain;
    object-position: center;
  }
}

.header-hero .image-container--text {
  z-index: 3;
  filter: drop-shadow(0 .3vmin .3vmin #00000080);
  width: 160vw;
  max-width: 1400px;
  margin: auto;
  position: absolute;
  inset: 34vmin 0 0;
  transform: translateX(-30vw);
}

@media (width >= 768px) {
  .header-hero .image-container--text {
    width: unset;
    inset: 0;
    transform: none;
  }
}

.header-hero .image-container--text img {
  object-fit: contain;
  object-position: top center;
}

@media (width >= 768px) {
  .header-hero .image-container--text img {
    object-fit: contain;
    object-position: center;
  }
}

.header-hero__characters {
  z-index: 2;
  justify-content: space-between;
  max-width: 1720px;
  margin: auto;
  display: flex;
  position: relative;
}

.header-hero__characters .image-container--zeus {
  transform-origin: 100% 100%;
  aspect-ratio: 1;
  position: relative;
  transform: scale(1.5)translate(5%, 10%);
}

@media (width >= 768px) {
  .header-hero__characters .image-container--zeus {
    width: min(50%, 800px);
    transform: translateX(-30%);
  }
}

.header-hero__characters .image-container--zeus img.character-glow-zeus {
  filter: drop-shadow(0 calc(var(--shadow-displacement) * .8vmin) 1.8vmin hsla(var(--shadow-color), 100%, 70%, 1)) drop-shadow(0 calc(var(--shadow-displacement) * -1.7vmin) 1.4vmin hsla(calc(var(--shadow-color)  + 20), 90%, 50%, .6));
}

.header-hero__characters .image-container--trait {
  transform-origin: 0 100%;
  aspect-ratio: 1;
  position: relative;
  transform: scale(1.5)translate(5%, 10%);
}

@media (width >= 768px) {
  .header-hero__characters .image-container--trait {
    width: min(50%, 800px);
    transform: translateX(30%);
  }
}

.header-hero__characters .image-container--trait img.character-glow-trait {
  filter: drop-shadow(0 calc(var(--shadow-displacement) * .8vmin) 1.8vmin hsla(var(--shadow-color), 100%, 50%, 1)) drop-shadow(0 calc(var(--shadow-displacement) * -1.7vmin) 1.4vmin hsla(calc(var(--shadow-color)  + 20), 90%, 30%, .6));
}

.header-hero .image-container--hero-background {
  margin: auto;
  position: absolute;
  inset: 0;
}

.header-hero .image-container--hero-background img {
  object-fit: cover;
  object-position: bottom;
}

.logotype {
  z-index: 8;
  align-items: center;
  gap: 0 1.4vmin;
  max-width: 1600px;
  margin: auto;
  display: inline-flex;
  position: absolute;
  inset: 2vmin 0 auto 4vmin;
}

.logotype__star-svg {
  aspect-ratio: 1;
  width: min(7vmin, 60px);
  height: min(7vmin, 60px);
}

.logotype__champion-svg {
  aspect-ratio: 30 / 7;
  fill: var(--c-white);
  width: min(15vmin, 140px);
}

.modal {
  z-index: var(--z-index-modal);
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  min-height: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.modal--out {
  pointer-events: none;
}

.modal__backdrop {
  z-index: 1;
  opacity: 0;
  background: #0f0f10;
  position: fixed;
  inset: 0;
}

.modal--in .modal__backdrop {
  opacity: .72;
  transition: opacity .4s cubic-bezier(0, 0, .5, 1);
}

.modal--out .modal__backdrop {
  opacity: 0;
  transition: opacity .55s cubic-bezier(.24, 0, .71, 1.01);
}

.modal__scroller {
  pointer-events: none;
  z-index: 2;
  place-content: center;
  min-height: 100%;
  display: grid;
  position: relative;
}

.modal__container {
  z-index: 3;
  padding: 20px 15px;
  position: relative;
}

.modal--out .modal__container {
  pointer-events: none;
}

.modal--in .modal__container {
  pointer-events: auto;
}

.modal-content {
  opacity: 0;
  border-radius: 1px;
  width: fit-content;
  margin: auto;
  display: flex;
  position: relative;
  transform: translateY(-30px);
}

.modal--in .modal-content {
  opacity: 1;
  transition: opacity .525s cubic-bezier(.61, 1, .88, 1), transform .525s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.modal--out .modal-content {
  pointer-events: none;
  opacity: 0;
  transition: opacity .625s cubic-bezier(0, 0, .5, 1), transform .675s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.modal-content__close {
  cursor: pointer;
  z-index: 1;
  place-content: center;
  width: 42px;
  height: 42px;
  margin: auto;
  display: grid;
  position: absolute;
  inset: 0 0 auto auto;
  transform: translateX(calc(-1 * max(10px, 1vmin) + 9px));
}

.modal-content__close .svg-icon {
  fill: var(--c-white);
  width: 24px;
  height: 24px;
}

.modal-registration {
  background-color: #00000080;
  border-radius: max(10px, 1vmin);
  padding: 42px max(10px, 1vmin) max(20px, 2vmin);
}

.modal-registration__image {
  border-radius: max(10px, 1vmin);
  max-width: 600px;
  overflow: hidden;
}

.modal-registration__button {
  padding-top: max(20px, 2vmin);
}

.btn {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 50%, 100% 50%;
  border-radius: 9.5px;
  place-content: center;
  width: fit-content;
  height: 46px;
  margin: auto;
  padding: 0 max(20px, 3vmin);
  font-size: 16px;
  font-weight: 600;
  line-height: 0;
  text-decoration: none;
  display: grid;
}

@media (width >= 768px) {
  .btn {
    height: 52px;
    font-size: 18px;
  }
}

.btn--orange {
  background: linear-gradient(to right, var(--c-orange-pink), var(--c-bright-pink));
}

.btn--voilet {
  background: linear-gradient(to right, var(--c-blue-dust), var(--c-bright-pink));
}

.image-container--app-download {
  aspect-ratio: 68 / 49.4;
  border-radius: 20px;
  overflow: hidden;
}

.manual-image {
  max-width: 100%;
  height: auto;
}
/*# sourceMappingURL=index.095ab99c.css.map */
