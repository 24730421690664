.headline-medium {
  font-weight: 700;
}

.headline-large {
  font-weight: 600;
  font-size: 3.2em;
}

.headline-small {
  font-weight: 600;
  font-size: 2.4em;
}

.title-medium {
  max-inline-size: none;
  font-weight: 600;
  font-size: 1.8rem;
}

.body-large {
  font-size: 1.6rem;
}

ul {
  margin-left: 36px;
  line-height: 2.4rem;
  font-size: 1.6rem;
}
