.modal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);
  width: 100vw;
  min-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &--out {
    pointer-events: none;
  }

  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 1;
    background: hsla(200 2% 6% / 1);
    opacity: 0;

    .modal--in & {
      opacity: .72;
      transition: opacity 400ms cubic-bezier(0, 0, 0.5, 1);
    }

    .modal--out & {
      opacity: 0;
      transition: opacity 550ms cubic-bezier(.24, 0, .71, 1.01);
    }
  }

  &__scroller {
    pointer-events: none;
    position: relative;
    z-index: 2;
    min-height: 100%;
    display: grid;
    place-content: center;
  }

  &__container {
    .modal--out & {
      pointer-events: none;
    }

    .modal--in & {
      pointer-events: auto;
    }

    position: relative;
    z-index: 3;
    padding: 20px 15px;
  }
}

.modal-content {
  position: relative;
  width: fit-content;
  margin: auto;
  border-radius: 1px;
  opacity: 0;
  transform: translateY(-30px);
  display: flex;

  .modal--in & {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 525ms cubic-bezier(.61, 1, .88, 1),
      transform 525ms cubic-bezier(.61, 1, .88, 1);
  }

  .modal--out & {
    pointer-events: none;
    opacity: 0;
    transform: translateY(40px);
    transition:
      opacity 625ms cubic-bezier(0, 0, 0.5, 1),
      transform 675ms cubic-bezier(.22, 1, .5, .95);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    inset: 0 0 auto auto;
    margin: auto;
    z-index: 1;
    display: grid;
    place-content: center;
    width: 42px;
    height: 42px;
    transform: translateX(calc((max(10px, 1vmin) * -1) + 9px));

    .svg-icon {
      width: 24px;
      height: 24px;
      fill: var(--c-white);
    }
  }
}
