:root {
  // z-orders
  --z-index-particles: 2;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-womans: 12;
  --z-index-modal: 100;

  // colors
  --c-white: rgb(255, 255, 255);
  --c-light-sand: rgb(254, 254, 178);
  --c-black-blue: rgb(14, 1, 35);
  --c-orange-pink: rgb(255, 159, 104);
  --c-bright-pink: rgb(255, 36, 191);
  --c-blue-dust: rgb(53, 38, 226);

  //
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));

  @media (width >= 992px) {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  //
  .gridline {
    --width: 1100px;
    --padding: 15px;
    --gap: 20px;

    @media (width >= 576px) {
      --padding: 40px;
    }
  }

  // fonts
  --min-fs: 0.6;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;

  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
