.logotype {
  position: absolute;
  inset: 2vmin 0 auto 4vmin;
  z-index: 8;
  display: inline-flex;
  align-items: center;
  gap: 0 1.4vmin;
  max-width: 1600px;
  margin: auto;

  &__star {
    &-svg {
      width: min(7vmin, 60px);
      height: min(7vmin, 60px);
      aspect-ratio: 1;
    }
  }

  &__champion {
    &-svg {
      width: min(15vmin, 140px);
      aspect-ratio: 30 / 7;
      fill: var(--c-white);
    }
  }
}
