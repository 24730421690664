html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  display: grid;
  width: 100vw;
  min-height: 100dvh;
  background-color: var(--c-black-blue);
  color: var(--c-white);
}

.layout {
  overflow: clip auto;
  width: 100vw;
  padding-bottom: var(--v-offset-lg);
}
